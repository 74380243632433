<template>
  <ion-page>
    <LoggedInNav :title="'Contacto'" :back="true" :backUrl="'/configuracion'" />

    <ion-content>
      <div class="centerCenter">
        <div class="contactoContenedor">
          <div class="contenedor" v-if="step1">
          <div class="centerCenter" style="padding: 30px 30px 10px 30px">
            <span class="parrafo"> ¿Con qué motivo nos contactas? </span>
          </div>

          <div class="centerCenter" style="margin-top: 10px">
            <div
              class="centerCenter"
              :class="badge1Selected ? 'selected' : 'notSelected'"
              v-on:click="selectedBadge('1')"
            >
              <table>
                <tr>
                  <td valign="center">
                    <span>SUGERENCIA &nbsp;</span>
                  </td>
                  <td valign="baseline">
                    <ion-icon
                      :icon="bulbOutline"
                      style="font-size: 25px"
                    ></ion-icon>
                  </td>
                </tr>
              </table>
            </div>
          </div>

        <div class="centerCenter" style="margin-top: 10px">
          <div
            class="centerCenter"
            :class="badge2Selected ? 'selected' : 'notSelected'"
            v-on:click="selectedBadge('2')"
          >
            <table>
              <tr>
                <td valign="center">
                  <span>CONSULTA &nbsp;</span>
                </td>
                <td valign="baseline" style="padding-top: 2.5px">
                  <ion-icon
                    :icon="helpCircleOutline"
                    style="font-size: 25px"
                  ></ion-icon>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="centerCenter" style="margin-top: 10px">
          <div
            class="centerCenter"
            :class="badge3Selected ? 'selected' : 'notSelected'"
            v-on:click="selectedBadge('3')"
          >
            <table>
              <tr>
                <td valign="center">
                  <span>PROBLEMA TÉCNICO &nbsp;</span>
                </td>
                <td valign="baseline">
                  <ion-icon
                    :icon="buildOutline"
                    style="font-size: 20px"
                  ></ion-icon>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div style="margin-top: 40px">
          <ion-button
            v-on:click="goToStep2"
            class="btn buttonIniciar"
            expand="block"
            fill="solid"
            >Siguiente</ion-button
          >
        </div>
      </div>

      <div class="contenedor" v-if="step2">
        <div class="centerCenter" style="padding: 30px 30px 10px 30px">
          <span class="parrafo">
            Si quieres detalla un poco más de que se trata para que podamos
            ayudarte
          </span>
        </div>

        <ion-textarea
          rows="8"
          placeholder="máx. 200 caracteres"
          v-model="textArea"
        ></ion-textarea>

        <div style="margin-top: 40px">
          <ion-button
            class="btn buttonIniciar"
            expand="block"
            fill="solid"
            v-on:click="openModal"
            :disabled="textArea === ''"
            >Enviar</ion-button
          >
        </div>
      </div>
        </div>
        
      </div>
      
    </ion-content>
  </ion-page>
</template>

<script>
import { defineComponent } from "vue";
import { IonPage, IonTextarea, modalController, IonIcon, IonButton, IonContent } from "@ionic/vue";
import LoggedInNav from "../../../components/LoggedInNav.vue";
import { helpCircleOutline, bulbOutline, buildOutline } from "ionicons/icons";
import Modal from "../../../components/Modals/ModalContactoGracias";
import AccountService from "../../../services/account.service";

export default defineComponent({
  name: "Contacto",
  components: {
    IonPage,
    IonIcon, 
    IonButton, 
    IonContent,
    LoggedInNav,
    IonTextarea,
  },
  data() {
    return {
      step1: true,
      step2: false,
      badge1Selected: true,
      badge2Selected: false,
      badge3Selected: false,
      subject: "",
      textArea: "",
    };
  },
  methods: {
    async openModal() {
      const modal = await modalController.create({
        component: Modal,
        mode: "md"
      });
      console.log(this.subject, this.textArea);
      AccountService.contact({
        subject: this.subject,
        message: this.textArea,
      }).then((data) => {
        if (data === "success") {
          return modal.present();
        }
      });
    },
    goToStep2() {
      this.step1 = false;
      this.step2 = true;
    },
    selectedBadge(badge) {
      if (badge === "1") {
        this.badge1Selected = true;
        this.badge2Selected = false;
        this.badge3Selected = false;
        this.subject = "Sugerencia";
      } else if (badge === "2") {
        this.badge1Selected = false;
        this.badge2Selected = true;
        this.badge3Selected = false;
        this.subject = "Consulta";
      } else {
        this.badge1Selected = false;
        this.badge2Selected = false;
        this.badge3Selected = true;
        this.subject = "Problema Técnico";
      }
    },
  },
  setup() {
    return {
      helpCircleOutline,
      bulbOutline,
      buildOutline,
    };
  },
});
</script>

<style scoped>
ion-textarea {
  --background: white;
  border: 1px solid #b4b4b4;
}

.selected {
  font-family: "Avenir";
  font-size: 15px;
  color: #e33613;
  background-color: #ffffff00;
  border-radius: 5px;
  border: 2px solid #e33613;
  width: 100%;
  height: 45px;
  letter-spacing: 1px;
  padding-top: 5px;
}

.notSelected {
  font-family: "Avenir";
  font-size: 15px;
  color: #c4c4c4;
  background-color: #ffffff00;
  border-radius: 5px;
  border: 2px solid #c4c4c4;
  width: 100%;
  height: 45px;
  letter-spacing: 1px;
  padding-top: 5px;
}

.contactoContenedor {
    width: 100%;
  }
  @media (min-width: 720px) {
    .contactoContenedor {
      width: 720px;
    }
  }
</style>

